import React, {Fragment, useRef, useState} from 'react';
import Slider from 'react-slick';
import ModalVideos from '../ModalVideo/ModalVideos';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderContent = [
    {
        img: 'team4.png',
        icon: 'bi bi-play-fill',
        plogo: 'Plogo-15',
        title: 'Sales Specialists.',
        desc: 'We have a team of highly qualified sales specialists that will analysing your sales process to identify any roadblocks.',
        text: 'Contact Team',
        arrow: 'icon_14'
    },
    {
        img: 'team2.png',
        icon: 'bi bi-play-fill',
        plogo: 'Plogo-15',
        title: 'Success Engineers.',
        desc: 'They are the team responsible for your success bring you the whole potential of our solution and AI.',
        text: 'Contact Team',
        arrow: 'icon_14'
    },
    {
        img: 'team5.png',
        icon: 'bi bi-play-fill',
        plogo: 'Plogo-15',
        title: 'Integration Architects.',
        desc: 'The integration architect will analyse your data architecture to pull insights off your existing data sources.',
        text: 'Contact Team',
        arrow: 'icon_14'
    },
    {
        img: 'team3.png',
        icon: 'bi bi-play-fill',
        plogo: 'Plogo-15',
        title: 'Executives Leaders.',
        desc: 'A team of experienced executive leaders will help your leadership scale your sales strategy on a global level.',
        text: 'Contact Team',
        arrow: 'icon_14'
    },
   
]

const settings2 = {
    arrow:true,
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        }, 
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                initialSlide: 1
            }
        }, 
         {
             breakpoint: 575,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
        }
    ]
};




const TestimonialTwo = () => {
    const [isOpen,
        setOpen] = useState(false);

    const openModal = () => {
        setOpen(!isOpen)
    }


    return (
        <Fragment>
            <ModalVideos isOpen={isOpen} onClick={openModal}/>

            <Slider className="feedback_slider_two" {...settings2}>
                {SliderContent.map((val, i)=>(
                    <div className="item" key={i}>
                    <div className="feedback-block-two d-sm-flex">
                        <div className="img-meta hidden">
                            <img src={`images/assets/${val.img}`} alt="images"/>
                            {/*<button className="fancybox video-icon" onClick={openModal}>
                                <i className={val.icon}/>
                </button>*/}    
                        </div>
                        <div className="text-wrapper">
                            {/*<div className="icon d-flex align-items-end"><img src={`images/logo/${val.plogo}.png`} alt="p-logo"/>
                            </div>*/}
                            <div className="camp-name">{val.title}</div>
                            <p>{val.desc}</p>
                            <a
                                href="/contact"
                                className="read-btn d-flex align-items-center justify-content-between">
                                <span>{val.text}</span>
                                {/*<img src={`images/icon/${val.arrow}.svg`} alt="" className="arrow"/>*/}
                            </a>
                        </div>
                        {/* /.text-wrapper */}
                    </div>
                    {/* /.feedback-block-two */}
                </div>
                ))}
            </Slider>
            {/* /.feedback_slider_two */}
        </Fragment>
    )
}

export default TestimonialTwo