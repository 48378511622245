import React, {Fragment} from 'react'

const Skill = () => {
    return (
        <Fragment>
            <div className="skills-progress skills">
                <div className="habilidades_contenedor">
                    <div className="codeconSkills">
                        <div className="codeconSkillbar skill-1 mb-80 md-mb-60">
                            <div className="skill-text">
                                <span className="codeconSkillArea">Process</span>
                            </div>
                            <div
                                className="skillBar"
                                data-percent="40%"
                                style={{
                                background: '#9fc5e8'
                            }}>
                                <span className="PercentText">40%</span>
                            </div>
                        </div>
                    
                        <div className="codeconSkillbar skill-2  mb-80 md-mb-60">
                            <div className="skill-text">
                                <span className="codeconSkillArea ">Automation</span>
                            </div>
                            <div
                                className="skillBar"
                                data-percent="90%"
                                style={{
                                background: '#00FF87'
                            }}>
                                <span className="PercentText">60%</span>
                            </div>
                        </div>

                        
                        <div className="codeconSkillbar skill-3  mb-80 md-mb-60">
                            <div className="skill-text">
                                <span className="codeconSkillArea ">Data Driven Decisions</span>
                            </div>
                            <div
                                className="skillBar"
                                data-percent="90%"
                                style={{
                                background: '#00E8FA'
                            }}>
                                <span className="PercentText">80%</span>
                            </div>
                        </div>

                        
                        <div className="codeconSkillbar skill-4  mb-80 md-mb-60">
                            <div className="skill-text">
                                <span className="codeconSkillArea ">Continuous Improvement</span>
                            </div>
                            <div
                                className="skillBar"
                                data-percent="90%"
                                style={{
                                background: '#0087FF'
                            }}>
                                <span className="PercentText">100%</span>
                            </div>
                        </div>

                      

                        
                    </div>
                    {/* /.codeconSkills */}
                </div>
                {/* /.habilidades_contenedor */}
            </div>
        </Fragment>
    )
}

export default Skill