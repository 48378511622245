import React, {Fragment} from 'react';


const ContactThree = () => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-4">
                    <div className="address-block-two text-center mb-40 sm-mb-20">
                        <div className="icon d-flex align-items-center justify-content-center m-auto"><img src="images/icon/icon_17.svg" alt=""/></div>
                        <h5 className="title">Our Address</h5>
                        <p>City of Paris
                            <br/>Paris, France</p>
                    </div>
                    {/* /.address-block-two */}
                </div>
                <div className="col-md-4">
                    <div className="address-block-two text-center mb-40 sm-mb-20">
                        <div className="icon d-flex align-items-center justify-content-center m-auto"><img src="images/icon/icon_18.svg" alt=""/></div>
                        <h5 className="title">Contact Info</h5>
                        <p>Open for a chat , email us
                            <br/>
                            <a href="#" className="webaddress">contact@salesprototype.com</a>
                        </p>
                    </div>
                    {/* /.address-block-two */}
                </div>
                <div className="col-md-4">
                    <div className="address-block-two text-center mb-40 sm-mb-20">
                        <div className="icon d-flex align-items-center justify-content-center m-auto"><img src="images/icon/icon_19.svg" alt=""/></div>
                        <h5 className="title">Support</h5>
                        <p>Our support team
                            <br/>
                            <a href="#" className="webaddress">support@salesprototype.com</a>
                        </p>
                    </div>
                    {/* /.address-block-two */}
                </div>
            </div>
        </Fragment>
    )
}

export default ContactThree