import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

const SocialContent = [
    {
        icon: 'fab fa-facebook-f',
        routerPath: '#'
    }, {
        icon: 'fab fa-twitter',
        routerPath: '#'
    }, {
        icon: 'fab fa-linkedin-in',
        routerPath: '#'
    }
];
const PageContent = [
    {
        name: 'Home',
        routerPath: '/'
    }, {
        name: 'Contact',
        routerPath: '/contact'
    }
];
const ServiceContent = [
    {
        name: 'Artificial Intelligence',
        routerPath: '/service-details'
    }, {
        name: 'Data Analytics',
        routerPath: '/service-details'
    }, {
        name: 'Data Visualization',
        routerPath: '/service-details'
    }, {
        name: 'Deep Learning',
        routerPath: '/service-details'
    }, {
        name: 'Statistical Modeling',
        routerPath: '/service-details'
    }
];
const LegalContent = [
    {
        name: 'Terms of use',
        routerPath: '/faq'
    }, {
        name: 'Terms &amp; conditions',
        routerPath: '/faq'
    }, {
        name: 'Privacy policy',
        routerPath: '/faq'
    }, {
        name: 'Cookie policy',
        routerPath: '/faq'
    }
];

const FooterFour = () => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-lg-4 footer-intro mb-40">
                    <div className="logo">
                        <Link to="/"><img src="images/logo/logo_01.png" alt="" width={130}/></Link>
                    </div>
                    <p>
                    <h6>Sales Teams. Powered by AI.</h6>
                    We will assist your sales teams to be more more effective.
                    </p>
                    <ul className="d-flex social-icon style-none">
                        {SocialContent.map((val, i) => (
                            <li key={i}>
                                <a href={val.routerPath}><i className={val.icon}/></a>
                            </li>
                        ))}
                    </ul>
                </div>
               
                
               
            </div>
        </Fragment>
    )
}

export default FooterFour