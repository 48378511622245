import React, {Fragment} from 'react';
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';

const FaqContent = [
    {
        title: 'What is SalesPrototype?',
        desc: `SalesPrototype is an AI based SaaS solution that helps your sales teams take more data-driven decisions 
        when chasing opportunities. It leverages AI and advanced sales methodologies to empower your sales teams.`,
        expand: 'a'
    }, {
        title: 'Can I already sign-up and start using SalesPrototype and your AI?',
        desc: `Unfortunately you cannot sign up yet, we are soon going into   alpha-testing stage but you can always
        send us an email if you are interested to be part of our beta testers community.`,
        expand: 'b'
    }, {
        title: 'I am an early alpha-tester, how can I give feedback about the solution?',
        desc: `Your feedback is extremely valuable to us ! You can email us directly to support@salesprototype.com`,
        expand: 'c'
    },
]

{/*
, {
    title: 'How do you weigh different criteria in your process?',
    desc: `They not only understand what I say but read between the lines and also give
    me ideas of my own. AI technology is perfect for best business solutions.`,
    expand: 'b'
}, {
    title: 'How can I build my sales process with salesprototype?',
    desc: `They not only understand what I say but read between the lines and also give
    me ideas of my own. AI technology is perfect for best business solutions.`,
    expand: 'c'
}, {
    title: 'If I already have a sales process, can I use it in salesprototype ?',
    desc: `They not only understand what I say but read between the lines and also give
    me ideas of my own. AI technology is perfect for best business solutions.`,
    expand: 'd'
}, {
    title: 'How can I accept credit cards online?',
    desc: `They not only understand what I say but read between the lines and also give
    me ideas of my own. AI technology is perfect for best business solutions.`,
    expand: 'e'
}
*/}

const Faq = () => {
    return (
        <Fragment>
            <Accordion
                className="accordion-style-one"
                preExpanded={["d"]}
                allowZeroExpanded>
                {FaqContent.map((item, i) => (
                    <AccordionItem className="accordion-item" key={i} uuid={item.expand}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <button className="accordion-button" type="button">
                                    {item.title}
                                </button>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel className="accordion-body fadeInUp">
                            <p>
                                {item.desc}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </Fragment>
    )
}

export default Faq